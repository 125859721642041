import React from 'react';
import NeowintechLogo from './icons/neowintech.svg';
import IbanLogo from './icons/iban.svg';
import SafeBrokLogo from './icons/SafeBrok-MKP.svg';

const getBrand = (applicationID: string) => {
  switch (applicationID) {
    case 'groot':
      return 'iban';
    case 'safebrok':
      return 'safebrok';
    default:
      return 'neowintech';
  }
}

const currentBrand: Brand = getBrand(process.env.APPLICATION_ID || 'safebrok');

const Logo = ({ ...props }) => {
  switch (currentBrand) {
    case 'neowintech':
      return <NeowintechLogo {...props} />;
    case 'iban':
      return <IbanLogo {...props} />;
    case 'safebrok':
      return <SafeBrokLogo {...props} />;
    default:
      return null;
  }
};

export default Logo;
