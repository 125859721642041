import { useCallback, useEffect } from 'react';
import { useIntl } from 'context/IntlContext';
import { useStaticQuery, graphql } from 'gatsby';
import theme from 'theme';
// import Logo from '../../images/icons/neowintech.svg';

const chatQuery = graphql`
  query ChatQuery {
    site {
      siteMetadata {
        chat {
          status
          token
        }
      }
    }
    allFile(filter: { name: { eq: "chatLogo" } }) {
      nodes {
        publicURL
      }
    }
  }
`;

const Freshchat = () => {
  const { locale } = useIntl();

  const data = useStaticQuery(chatQuery);
  const projectId = process.env.APPLICATION_ID;

  const initFreshchat = useCallback(() => {

    window.fcWidget.init({
      token: data.site.siteMetadata.chat.token,
      host: 'https://wchat.freshchat.com',
      locale,
      tags: ['safebrok'],
      siteId: 'safebrok',
      faqTags: {
        // Array of Tags
        tags: ['safebrok'],
        filterType: 'category',
      },
      config: {
        headerProperty: {
          backgroundColor: theme.colors?.quaternary,
          foregroundColor: theme.colors?.tertiary,
        },
      },
    });
  }, [
    data.site.siteMetadata.chat.token,
    locale,
    theme.colors?.quaternary,
    theme.colors?.tertiary,
  ]);

  const addFreshchatScript = useCallback(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://wchat.freshchat.com/js/widget.js';
    script.onload = () => initFreshchat();
    document.head.appendChild(script);
  }, [initFreshchat]);

  useEffect(() => {
    // if (!data.site.siteMetadata.chat.status) return;

    setTimeout(() => {
      addFreshchatScript();
    }, 3000);
  }, [addFreshchatScript, data.site.siteMetadata.chat.status]);

  useEffect(() => {
    let cancel = false;

    if (window.fcWidget) {
      setTimeout(() => {
        if (cancel) return;
        initFreshchat();
      }, 500);
    }

    return () => {
      cancel = true;

      if (!window.fcWidget) return;
      window.fcWidget.destroy();
    };
  }, [initFreshchat, locale]);

  return null;
};

export default Freshchat;
